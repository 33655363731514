@import "../../designTokens";

.triangle-score {
  background-image: url("../../assets/triangle-with-circle.svg");
  .score {
    padding-top: 32px;
    font-size: 16pt;
  }
  .defense {
    padding-top: 8px;
    color: #bbb;
  }
}

.circle-defense-score {
  background-image: url("../../assets/circle4.svg");
  background-size: contain;
  background-repeat: no-repeat;
  padding: $spacing-x-small;
  width: 40px;
  height: 40px;
  top: -98px;
  left: -10px;
  position: relative;
  > .label {
    font-size: 5pt;
    color: rgb(255,70,5);
  }
  > .denied-dex-score {
    line-height: 1.1;
    margin-top: 7px;
  }
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255,70,5);
}
.score-container {
  .ability {
    color: #bbb;
  }
  .score {
    font-weight: 600;
    color: #ddd;
  }
  .modifier {
    font-weight: 600;
    color: #ddd;
  }
}

.defense-score-container {
  width: 100%;
  display: flex;
  padding-bottom: $spacing-x-small;
  padding-top: $spacing-small;
  margin-bottom: -30px;
  border-top: 1px solid $accentColor;
  > .score-container {
    flex: 1;
  }
}