@import "../../designTokens";

.fp-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fp-box {
  width: 36px;
  height: 36px;
  background-image: url("../../assets/square2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .fp-total {
    text-align: center;
    font-size: 10pt;
    color: #fff;
  }
}

.fp-label {
  color: #ddd;
  font-size: 10pt;
  padding-left: $spacing-xx-small;
}