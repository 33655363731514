@import "../../designTokens";

.character-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.image-with-score {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.top-bar {
  display: flex;
  > * {
    margin-right: $spacing-large;
  }
  > *:last-child {
    margin-right: 0;
  }
}