@import "../designTokens";

.my-characters-app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .nav-container {
    width: 100%;
    .nav-title {
      width: 100%;
      color: #ccc;
    }
  }

  background-image: url("../assets/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.welcome-text {
  background-color: $backgroundColorDarkBlue;
  opacity: 0.7;
}

#root {
  height: 100%;
}

.img-button-icon {
  height: 48px;
  width: 48px;
  padding: 0.5rem;
  fill: #bbb;
  cursor: pointer;
}