@import "../../designTokens";

.carousel-control {
  opacity: 0.8;
  .carousel-control-next-icon {
    padding: 20px;
    background-color: rgba(18, 35, 54, 0.8);
    border-radius: 1rem;
    border: 2px solid #4ba5be;
  }
  .carousel-control-prev-icon {
    padding: 20px;
    background-color: rgba(18, 35, 54, 0.8);
    border-radius: 1rem;
    border: 2px solid #4ba5be;
  }
}

.builder-species-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #ddd;
  .btn-group-vertical {
    width: 100%;
  }

  .species-carousel-item {
    .species-feature-container {
      padding-left: 135px;
      padding-right: 135px;
    }
  }

  button {
    color: #ddd
  }
  .btn-primary {
    background-color: $accentColorDark;
    border-color: $accentColorDark;
  }
}

.species-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}