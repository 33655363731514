@import "../../designTokens";

.init-box-container {
  display:flex;
  padding-top: $spacing-small;
}
.init-box {
  padding-top: 10px;
  width: 72px;
  height: 72px;
  min-width: 72px;
  background-image: url("../../assets/square2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ddd;
}

.init-total {
margin-top: 15px;
}

.init-label {
  margin-top: 25px;
}