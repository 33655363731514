@import "../../designTokens";

.character-header-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $spacing-large;
  background-color: rgba(65,65,65, 0.7);

  .header {
    width: 100%;
    max-width: 1200px;
    padding: 0 $spacing-x-small $spacing-medium 0;
    display: flex;
    align-items: center;

    .img-border {
      border: 2px solid $accentColor;
      border-radius: 0.5rem 0.5rem;
      height: fit-content;
      overflow: hidden;
    }

    img {
      width: 65px;
      height: 65px;
    }
  }
}

.menu {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  .toggle.btn {
    background-color: transparent;
    border: none;
  }
  .toggle {
    > img {
      width: 50px;
      height: 50px;
    }

    > img:first-child {
      transform: scaleX(-1);
      position: absolute;
    }

    > img:last-child {

    }
  }
}

.basic-stats {
  display: flex;
  flex-direction: column;
  font-size: 11pt;
  margin-left: $spacing-medium;
  > .name {
    font-size: 16pt;
    font-weight: 500;
    line-height: 1.2;
    color: #ccc;
  }
  > .description {
    font-size: 10pt;
    color: #bbb;
  }
  > .level {
    font-size: 8pt;
    color: #aaa;
  }
}