@import "../../designTokens";

.features-container {
  height: 100%;
  background-color: $backgroundColorAccent;
  border-radius: 1rem 1rem;
  overflow: hidden;
}

.features {
  height: 100%;
  overflow-y: scroll;
  overflow-x: scroll;

  overflow-wrap: break-word;
  color: #ddd;
}

.features-table {
  background-color: $backgroundColorAccent;
  th {
    border-color: $accentColor;
    background-color: $backgroundColorAccent;
    color: #bbb;
    font-size: small;
  }
  td {
    border-color: $accentColor;
    background-color: $backgroundColorAccent;
    color: #bbb;
    font-size: small;
  }
  td:first-child {
    font-weight: bold;
  }
}