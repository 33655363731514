@import "../../designTokens";

.carousel-control {
  opacity: 0.8;
  .carousel-control-next-icon {
    padding: 20px;
    background-color: rgba(18, 35, 54, 0.8);
    border-radius: 1rem;
    border: 2px solid #4ba5be;
  }
  .carousel-control-prev-icon {
    padding: 20px;
    background-color: rgba(18, 35, 54, 0.8);
    border-radius: 1rem;
    border: 2px solid #4ba5be;
  }
}

.level-up-button {
  background-color: $backgroundColor;
  border-color: $accentColor;
  span {
    color: $accentColorDark;
  }
}
.level-up-button:hover {
  background-color: $accentColor;
  span {
    color: #222;
  }
}

.builder-class-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #ddd;
  .btn-group-vertical {
    width: 100%;
  }

  .class-carousel-item {
    .class-feature-container {
      padding-left: 135px;
      padding-right: 135px;
    }
  }

  button {
    color: #ddd
  }
  .btn-primary {
    background-color: $accentColorDark;
    border-color: $accentColorDark;
  }
}

.class-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}