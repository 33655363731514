@import "../../designTokens";

.builder-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: $spacing-medium;
}

.builder-content {
  padding: $spacing-large;
  margin-top: 7px;
}

.gender-group {
  width: 304px;
  margin-top: $spacing-medium;
  .btn.active {
    background-color: $accentColorDark;
    color: #ddd;
  }
  .btn-outline-primary {
    border-color: $accentColorDark;
    color: #bbb;
  }
}

.input-with-label {
  display: flex;
  flex-direction: column;

  .label {
    color: #ddd;
  }

  .input {
    display: flex;
    width: 400px;
    > Input {
      width: 304px;
    }
  }
  .btn {
    margin-left: 10px;
  }
}

.name-row {
  display: flex;
  .btn.btn-secondary {
    background-color: $accentColorDark;
    color: #ddd;
  }
}