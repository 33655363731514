@import "../../designTokens";

.skill-name {
  color: #ddd;
}
.skill-key-ability {
  font-size: small;
  width: 50px;
  color: #bbb;
}

.skill-bonus {
  color: #bbb;
}

.skills-container {
  &.box {
    padding: 0;
    overflow: scroll;
    background-color: $backgroundColorAccent;
  }
}

.skills-table {
  font-size: medium;
  width: 300px;
  border-color: $accentColor;
  background-color: $backgroundColorAccent;
  tbody {
    background-color: $backgroundColorAccent;
    tr {
      td {
        background-color: $backgroundColorAccent;
      }
      td:last-child {
        text-align: end;
      }
    }
  }
}

.dot {
  height: 14px;
  width: 14px;
  background-color: rgba(0,0,0,0);
  border-radius: 50%;
  border: 1px solid $accentColor;
  display: inline-block;
}

.dot-trained {
  background-color: $accentColor;
}

.dot-focused {
  background-color: green;
  border-color: green;
}