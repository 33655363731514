@import "../designTokens";

.base-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url("../assets/bg3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .header {
    font-size: 24px;
    color: #bbb;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 336px;
    width: 600px;
    padding-bottom: $spacing-xx-large;
  }
  .content {
    display: flex;
    flex-direction: column;

    .image {
      width: 21em;
      text-align: center;
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: 100%;
      }
      .quote {
        color: #888;
        font-size: small;
      }
      .register-error {
        color: darkred;
        font-size: medium;
      }
    }

    .form {
      margin-top: 2em;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: fit-content;
        label {
          font-size: 20px;
          color: $accentColor;
        }
        input {
          margin-top: 6px;
          min-width: 18em;
          height: 37px;
          padding: 0px 10px;
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          background-color: #ddd;
          color: black;
          border: 0;
          border-radius: 4px;
          margin-bottom: 31px;
          transition: all 250ms ease-in-out;
          &:hover {
            //background-color: #ffffff;
            //box-shadow: 0px 0px 14px 0.3px #0e81ce96;
          }

          &:focus {
            outline: none;
            box-shadow: 0px 0px 12px 0.8px #3474dbb2;
          }
        }
      }
    }
  }

  .login-button {
    width: 292px;
  }

  .footer-login-methods {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #bbb;
    width: 100%;
    row-gap: $spacing-small;
  }

  .footer {
    color: #bbb;
    margin-top: $spacing-large;
    display: flex;
    flex-direction: column;
    align-items: center;
    .register {
      margin-top: $spacing-small;
      display: flex;
      column-gap: 10px;
    }
    a {
      color: $accentColor;
    }
  }
}