@import "../../designTokens";


.feat-talent-choices-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 768px) {
    .accordion {
      max-width: 368px;
    }
  }
  .feature-accordion {
    width: 646px;
  }

  .header {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    color: #ddd;
    padding: 0;
    margin-bottom: $spacing-small;
    background-color: $backgroundColorAccentOpaque;
    .feature-accordion {
      padding: $spacing-large;
    }

    .name {
      font-size: xx-large;
      flex: 1;
    }
  }
  .title {
    display:flex;
    align-items: center;
    border-bottom: 2px solid white;
    padding: $spacing-x-small;
    background-color: $backgroundColorDarkBlueOpaque;
    border-radius: 1rem 1rem 0 0;
    border-bottom: 2px solid $accentColor;
  }
  .header {
    padding: 0;
  }
}