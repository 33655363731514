@import "../../designTokens";

.no-characters {
  font-size: x-large;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.welcome-info {
  background-color: $backgroundColorDarkBlue;
  color: #bbb;
  padding: $spacing-medium $spacing-large 0 $spacing-medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.9;
  margin-bottom: $spacing-large;
  h3 {
    font-size: x-large;
    margin-bottom: $spacing-medium;
  }
  p {
    font-size: large;
    color: #999;
  }
}

.get-started-button {
  background-color: $backgroundColorDarkBlue;
  border: 2px solid $accentColorDark;
  color: #bbb;
  opacity: 0.9;
}
.get-started-button:hover {
  background-color: $backgroundColorAccent;
  border: 2px solid $accentColor;
  color: #ccc;
  opacity: 0.9;
}

.character-cards-list {
  display: grid;
  gap: $spacing-large;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  width: 90%;
}

@media only screen and (max-width: 768px) {
  .character-cards-list {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1em;
    grid-row-gap: $spacing-large;
  }
}

.character-cards-list > * {
  max-width: 400px;
  background-color: transparent;
}
.character-card {
  padding: 0;
  overflow:hidden;
  min-width: 340px;
  .card-body {
    background-color: $backgroundColorDarkBlueOpaque;
    .img-border {
      border: 2px solid $accentColor;
      border-radius: 0.5rem 0.5rem;
      height: fit-content;
      overflow: hidden;
    }
    img {
      width: 65px;
      height: 65px;
    }
  }
  .character-info {
    display: flex;
  }
  .basic-stats {
    > .description {
      font-size: 9pt;
      color: #bbb;
    }
  }
  .card-footer {
    background-color: $backgroundColorAccentOpaque;
  }
}

.card-footer {
  .nav-pills {
    .nav-link {
      a {
        color: #bbb;
      }
    }
  }
  .nav-row {
    display: flex;
    .right-align {
      margin-left: auto;
    }
  }
}