@import "../../designTokens";

.ability-score {
  background-image: url("../../assets/circle.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.circle-raw-score {
  background-image: url("../../assets/circle3.svg");
  background-size: contain;
  background-repeat: no-repeat;
  padding: $spacing-x-small;
  width: 40px;
  height: 40px;
  top: -37px;
  left: 60px;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
}
.score-container {
  .ability {
    color: #bbb;
  }
  .score {
    font-weight: 600;
    font-size: 14pt;
    color: #ddd;
  }
  .modifier {
    font-weight: 600;
    color: #ddd;
  }
}

.ability-score-container {
  display: flex;
  padding-bottom: $spacing-x-small;
  width: fit-content;
  margin-bottom: -20px;
}