$spacing-x-small: 0.5rem !default;
$spacing-xxx-small: 0.125rem !default;
$spacing-xx-small: 0.25rem !default;
$spacing-small: 0.75rem !default;
$spacing-medium: 1rem !default;
$spacing-none: 0 !default;
$spacing-x-large: 2rem !default;
$spacing-xx-large: 3rem !default;
$spacing-xxx-large: 5rem !default;
$spacing-large: 1.5rem !default;

$accentColor: #4ba5be;
$accentColorDark: #38869c;

$backgroundColorDarkBlue: rgb(18, 35, 54);
$backgroundColorDarkBlueOpaque: rgba(18, 35, 54, 0.8);

$backgroundColor: #282c34;

$backgroundColorAccent: rgb(0, 46, 61);
$backgroundColorAccentOpaque: rgb(0, 46, 61, 0.8);
$backgroundColorAccentTransparent: rgb(0, 46, 61, 0.4);