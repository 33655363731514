@import "../designTokens";

html, body {
  height: 100%;
  background-color: $backgroundColor;
}

#root {
  display: flex;
  justify-content: center;
  background-color: $backgroundColor;
}

.character-sheet-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  color: white;
  width: 100%;

  background-image: url("../assets/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    height: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
}
.character-sheet-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-medium;
  overflow: scroll;
  position: fixed;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 0;
}
.character-sheet {
  max-width: 1200px;
  width: 100%;
  display: flex;
}

.two-column {
  display: flex;
  > * {
    flex: 1;
  }
  > .column {
    display: flex;
    flex-direction: column;
  }
  > .column:first-child {
    margin-right: $spacing-x-small;
  }
  > .column:last-child {
    margin-left: $spacing-x-small;
  }
  .no-flex {
    flex: 0;
  }
  max-height:807px;
}

.box {
  border: 0.125rem solid $accentColor;
  border-radius: 1rem 1rem;
  padding: $spacing-x-small $spacing-x-small $spacing-medium;
}

.filled {
  background-color: $backgroundColorDarkBlue;
}

.filled-opaque {
  background-color: $backgroundColorDarkBlueOpaque;
}

.img-button-icon {
  height: 48px;
  width: 48px;
  padding: 0.5rem;
  fill: #bbb;
  cursor: pointer;
}