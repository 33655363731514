@import "../../designTokens";

.stack-box-container {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > * {
    padding-bottom: $spacing-xx-small;
  }
}