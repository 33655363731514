@import "../../designTokens";

.notes-container {
  //border: red 1px solid;
  margin: 0;
  height: 100%;
  .notes-input {
    height: 100%;
    resize: none;
    background-color: $backgroundColorAccent;
    color: #bbb;
    box-shadow: none;
    outline: none;
    border: 0;
    border-radius: 1rem 1rem;
  }
  .notes-input::placeholder {
    color: #666;
  }
  .label {
    font-weight: bold;
    color: #bbb;
    font-size: small;
  }
  .notes-input:focus {
    border-color: #bbb;
  }
}