@import "../../designTokens";

.feature-accordion {
  .feature {
    display: flex;
    flex-direction: column;
    .feature-description {
      margin-bottom: $spacing-x-small;
    }

    .feature-choice {
      &.has-linked-choice {
        .choice-description {
          margin-left: $spacing-large;
        }
      }

      .choice-description {
        margin-left: 0;
      }
      select {
        //margin-bottom: $spacing-small;
      }
    }
    .feature-subchoice {
      margin-left: $spacing-large;
      margin-top: $spacing-small;
    }

    //.description {
    //  padding-left: $spacing-large;
    //}
    //select:not(:nth-of-type(1)) {}
    //.description:not(:last-child) {
    //  margin-bottom: $spacing-small;
    //}
    //
    //select:not(:last-child) {
    //  margin-bottom: $spacing-small;
    //}
  }
}

.accordion-item {
  border-color: $accentColor;

  .accordion-button {
    display: flex;
    align-items: center;
  }

  .accordion-header {
    border: none;
    box-shadow: none;
    .badge {
      margin-left: $spacing-x-small;
      font-size: xx-small;
      background-color: red !important;
    }
  }

  .accordion-body {
    border: none;
    box-shadow: none;
    background-color: $backgroundColor;
  }

  .accordion-collapse {
    border: none;
    box-shadow: none;
    overflow: hidden;
    background-color: $backgroundColor;
  }
}

.accordion-button:not(.collapsed) {
  background-color: $accentColor;
  color: #222;
  border: none;
  box-shadow: none;
}

.accordion-button.collapsed {
  background-color: $accentColorDark;
  color: #222;
  border: none;
  box-shadow: none;
}

.accordion-button:focus {
  border: none;
  box-shadow: none;
}

.accordion-body {
  border: none;
  box-shadow: none;
  background-color: $backgroundColor;
  color: #bbb;
}

.feature-choice-dropdown-row {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-small;
}


.feature-table-modal {
  .modal-body {
    padding: 0px;
  }
}

//.table-modal {
//  height: 100%;
//  .modal-content {
//    height: 90%;
//  }
//}