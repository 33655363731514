@import "../../designTokens";

.icon-with-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  text-align: center;
  color: #bbb;
  background-color: $backgroundColorDarkBlue;
  font-size: x-small;
  cursor: pointer;

  &.active { // character HAS the talent
    background-color: #7a6118;
    color: #e1c46c;

    &.popover {
      border-color: yellow;
    }

    &:not(.popover) {
      border-color: #e1c46c;
    }

  }
  &.available {
    &:not(.active) {
      border-color: forestgreen;
      background-color: #06402B;
    }
    &.active {
      background-color: #7a6118;
      color: #e1c46c;

      &.popover {
        border-color: yellow;
      }

      &:not(.popover) {
        border-color: #e1c46c;
      }
    }
  }

  &:not(.available) {
    &:not(.active) {
      border-color: red;
      background-color: #8b0000;
      cursor: not-allowed;
    }
    &.active {
      background-color: #7a6118;
      color: #e1c46c;

      &.popover {
        border-color: yellow;
      }

      &:not(.popover) {
        border-color: #e1c46c;
      }
    }
  }

  &.highlighted { // it's in the tree
    &:not(.available):not(.active) {
      border-color: red;
    }
    &.available:not(.active) {
      //border-color: red;
      &.popover {
        border-color: green;
      }
    }
  }
}
.icon-with-label:hover {
  background-color: rgb(40,70,100);
  &.active {
    background-color: #816719;
    border-color: #e1c46c;
  }
}
.icon {
  margin: $spacing-x-small 0;
  width: 64px;
}
.label {
  margin-bottom: $spacing-x-small;
}

.tree-icon-list {
  display: flex;
  flex-direction: column;
  row-gap: $spacing-small;
  width: 85px;
}

.talent-trees-root {
  display:flex;
}

.talent-tree-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  //align-items: center;
  row-gap: $spacing-xx-large;
}

.talent-tree-level {
  display: flex;
  flex-direction: row;
  //justify-content: center;
  column-gap: $spacing-xx-large;

  width: 100%;
  .icon-with-label {
    width: 150px;
  }
}

.talent-popover {

  .popover {
    border: 2px solid $accentColor;
    border-radius: 8px;
    background-color: $backgroundColorDarkBlue;
  }
  .popover-inner {
    border: 0;
  }
  .popover-arrow::before {
    border-left-color: $accentColor;
  }
  .popover-arrow::after {
    border-right-color: $accentColor;
  }
  .popover-body {
    background-color: $backgroundColorAccent;
    border-radius: 8px;
    color: #ddd;
  }
  .popover-header {
    background-color: $backgroundColorDarkBlue;
    color: #ddd;
    border-bottom: 1px solid $accentColor;
    //border-top-left-radius: 8px;
    //border-top-right-radius: 8px;
    //border-radius: 0.5rem 0;
    //border: 2px solid $accentColor;
  }
}