@import '../../designTokens';

.proficiency {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-medium;
  .prof-label {
    font-weight: bold;
    color: #bbb;
    font-size: small;
  }
  .prof-list {
    color: #ddd;
  }
  padding-bottom: $spacing-x-small;
  border-bottom: 1px solid $accentColor;
}

.proficiency-container {
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing-xx-small;
  padding-left: $spacing-small;
  padding-right: $spacing-medium;
  background-color: $backgroundColorAccent;
}