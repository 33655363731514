@import "../designTokens";

html, body {
  height: 100%;
  background-color: $backgroundColor;
}

.overlay {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(128,128,128, 0.5);
}

.welcome {
  margin-bottom: $spacing-large;
}

.app-inner {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100vh;
  padding: $spacing-medium;
  color: white;
}
.nav-spacer {
  margin-top: 75px;
}
.character-builder-app {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../assets/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.builder-container {
  overflow: scroll;
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  bottom: 0;
}

.builder-container {
  > * {
    margin-top: 7px;
  }
}

.img-button-icon {
  height: 48px;
  width: 48px;
  padding: 0.5rem;
  fill: #bbb;
  cursor: pointer;
}