@import "../../designTokens";

.ability-score-assignment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .asi-container {
    width: 640px;
  }

  .header {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    color: #ddd;
    padding: 0;
    margin-bottom: $spacing-small;
    background-color: $backgroundColorAccentOpaque;
    .feature-accordion {
      padding: $spacing-large;
    }

    .name {
      font-size: xx-large;
      flex: 1;
    }
  }
  .title {
    display:flex;
    align-items: center;
    border-bottom: 2px solid white;
    padding: $spacing-x-small;
    background-color: $backgroundColorDarkBlueOpaque;
    border-radius: 1rem 1rem 0 0;
    border-bottom: 2px solid $accentColor;
  }
  .header {
    padding: 0;
  }
}

.asi-choice-container {
  padding: $spacing-large;
}

.ability-score-assignment {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.75rem;
}
.points-assignment {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ddd;
}

.score-selector-row {
  display: flex;
  width: 100%;
  align-items: stretch;
  column-gap: $spacing-medium;
}

@media only screen and (max-width: 768px) {
  .score-selector-row {
    max-width: 400px;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.score-selector-input {
  margin-bottom: $spacing-small;
}

.score-total {
  cursor: default;
}

.score-summary-tooltip {
  display: flex;
  flex-direction: column;
}

.score-selector {
  width: 80px;
  margin-right: $spacing-small;
  display: flex;
  flex-direction: column;
  .modification {
    font-size: small;
  }
}
.score-selector:last-child {
  margin-right: 0;
}