@import "../../designTokens";

.inventory-container {
  height: 100%;
  margin-top: $spacing-x-small;
  background-color: $backgroundColorAccent;
  border-radius: 1rem 1rem;
}

.inventory-canvas {
  background-color: $backgroundColorDarkBlue;
}
.canvas-header {
  color: #ddd;
}
.canvas-body {
  color: #bbb;
}

.inventory {
  height: 100%;
  color: #ddd;
  display: flex;
  flex-direction: column;

  .table-heading {
    width: 100%;
    font-weight: bold;
    color: #bbb;
    font-size: small;
    padding-bottom: $spacing-x-small;
  }
}

.equipment-table {
  background-color: $backgroundColorAccent;
  th {
    border-color: $accentColor;
    background-color: $backgroundColorAccent;
    color: #bbb;
    font-size: small;
  }
  tbody {
    td {
      vertical-align: middle;
    }
  }
  td {
    border-color: $accentColor;
    background-color: $backgroundColorAccent;
    color: #ddd;
    font-size: small;
  }
  td:first-child {
    font-weight: bold;
  }
}
.add-button {
  padding: 0 $spacing-xx-small;
  background-color: $accentColorDark;
  font-size: small;
  border-color: $accentColor;
  color: #ddd;
}

.no-border {
  border: none;
  td {
    border: none;
  }
}

.delete-button {
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  .icon {
    height: 18px;
    width: 18px;
    fill: #bbb;

  }
  &:hover {
    background-color: transparent;
  }
}


