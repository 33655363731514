@import "../../designTokens";

.class-title {
  cursor: pointer;
  &.no-collapse {
    cursor: default;
  }
  &.collapsed {
    min-width: 696px;
    border: none;
    border-radius: 1rem 1rem 1rem 1rem;
  }
  display:flex;
  align-items: center;
  border-bottom: 2px solid white;
  padding: $spacing-x-small;
  background-color: $backgroundColorDarkBlueOpaque;
  border-radius: 1rem 1rem 0 0;
  &.not-collapsed {
    border-bottom: 2px solid $accentColor;
  }
  &.no-collapse {
    border-bottom: 2px solid $accentColor;
  }
}
.cta {
  text-decoration: underline;
  cursor: pointer;
  color: #bbb;
}
.cta:last-child {
  margin-left :$spacing-x-small;
}

.class-header {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  color: #ddd;
  padding: 0;
  margin-bottom: $spacing-small;
  background-color: $backgroundColorAccentOpaque;
  .feature-accordion {
    padding: $spacing-large;
  }

  .class-name-flex {
    flex: 1;
    display: flex;
    align-items: baseline;
  }
  .class-level {
    color: #bbb;
    font-size: small;
  }
  .class-name {
    font-size: x-large;
    margin-right: $spacing-small;
    //flex: 1;
  }
  .class-name &.collapsed {

  }
  .class-details {
    display: flex;
    align-items: flex-start;
    padding: $spacing-x-small;
  }
  .class-description {
    padding: $spacing-x-small;
  }

  .sm-logo {
    height: 36px;
    width: 36px;
    border-radius: 0.5rem;
    border: 1px solid $accentColorDark;
    padding: 0;
    margin-right: $spacing-small;
  }

  img {
    width: 256px;
    height: 256px;
    padding: $spacing-x-small;
  }
}

.class-feature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 768px) {
    .accordion {
      max-width: 368px;
    }
  }

  .accordion {

  }
}