@import "../../designTokens";

.character-feature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 768px) {
    .accordion {
      max-width: 368px;
    }
  }

  .feature-accordion {
    width: 100%;
    max-width: 700px;
  }

  .accordion {

  }
}