@import "../../designTokens";

.hp-box-container {
  width: 100%;
  display: flex;
  //justify-content: center;
}

.hp-box {
  width: 220px;
  height: 115px;
  background-image: url("../../assets/rectangle3.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  .hp-total {
    text-align: center;
    font-size: 32pt;
    color: #add;
    padding-top: 18px;
  }

  .hp-label {
    flex: 1;
    display: flex;
    align-items: flex-end;
    padding-left: 30px;
    padding-bottom: 5px;
    color: #bbb;
    font-size: 10pt;
  }
}