@import "../../designTokens";

.dt-box {
  width: 100px;
  height: 100px;
  background-image: url("../../assets/hex2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  .dt-total {
    text-align: center;
    font-size: 16pt;
    padding-top: 22px;
  }

  .dt-label {
    flex: 1;
    font-size: 10pt;
    text-align: center;
    padding-top: 2px;
    color: #bbb;
  }
}