@import "../../designTokens";

.species-title {
  display:flex;
  align-items: center;
  border-bottom: 2px solid white;
  padding: $spacing-x-small;
  background-color: $backgroundColorDarkBlueOpaque;
  border-radius: 1rem 1rem 0 0;
  border-bottom: 2px solid $accentColor;
}
.cta {
  text-decoration: underline;
  cursor: pointer;
  color: #bbb;
}
.species-header {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  color: #ddd;
  padding: 0;
  margin-bottom: $spacing-small;
  background-color: $backgroundColorAccentOpaque;
  .feature-accordion {
    padding: $spacing-large;
  }

  .species-name {
    font-size: xx-large;
    flex: 1;
  }
  .species-details {
    display: flex;
    align-items: flex-start;
    padding: $spacing-x-small;
  }
  .species-description {
    padding: $spacing-x-small;
  }
  img {
    width: 256px;
    height: 256px;
    padding: $spacing-x-small;
  }
}

.species-feature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 768px) {
    .accordion {
      max-width: 368px;
    }
  }
}