@import "../../designTokens";

.spacer {
  flex: 1;
}

.nav-container {
  position: fixed;
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $spacing-large;
  background-color: rgba(65,65,65, 0.7);
}

.welcome {
  color: #ddd;
  font-size: 28pt;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .welcome {
    font-size: 18pt;
  }
  .nav-container {
    margin-bottom: 0;

  }
}

.nav-pills {
  .nav-item {
    .nav-link {
      display: flex;
      align-items: center;
      &.active {
        background-color: $accentColorDark;
        a {
          color: #ddd;
        }
      }
      &.disabled {
        a {
          color: #888;
        }
      }
      .badge {
        margin-left: $spacing-x-small;
        font-size: xx-small;
        background-color: red !important;
      }
    }
  }
  .nav-link {
    a {
      color: #ddd;
      text-decoration: none;
    }
  }
}