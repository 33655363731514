@import "../../designTokens";

.actions-container {
  height: 100%;
  background-color: $backgroundColorAccent;
  border-radius: 1rem 1rem;

  .nav-pills {
    font-size: x-small;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background: rgb(18, 35, 54);
    border-radius: 1rem 1rem 0 0;
    border-bottom: 2px solid $accentColor;
  }
  .nav-link {
    padding: 0.5rem;
  }
  .nav-item {
    cursor: pointer;
  }
}

.actions-tab-panel-content {

}

.actions-table {
  background-color: $backgroundColorAccent;
  th {
    border-color: $accentColor;
    background-color: $backgroundColorAccent;
    color: #bbb;
    font-size: small;
  }
  td {
    border-color: $accentColor;
    background-color: $backgroundColorAccent;
    color: #bbb;
    font-size: small;
  }
  td:first-child {
    font-weight: bold;
  }
}