@import "../../designTokens";

.feature-table-tr-current-selected {
  cursor: default;
  td {
    cursor: default;
  }
}

.modal-xl {
  .modal-header {
    .modal-title {
      width: 100%;
      line-height: 1.2;
    }
    background-color: $backgroundColorDarkBlueOpaque;
    border-bottom-color: #4ba5be;
    color: #bbb;

    .header-row {
      display: flex;
      align-items: center;
      width: 100%;

      .filters {
        margin-left: auto;
        align-items: center;
        display: flex;
      }

      .tree-filter {
        margin-right: 10px;
        align-items: center;
        display: flex;
        span {
          margin-right: 6px;
        }
      }

      select {
        max-width: 200px;

      }

      input {
        max-width: 200px;
      }

      .search-icon {
        margin: 0;
        position: absolute;
        right: 20px;
        background: transparent;
      }
    }
  }
  .modal-footer {
    border-top-color: #4ba5be;
    background-color: $backgroundColorDarkBlueOpaque;
  }
  .modal-content {
    min-height: 655px;
    max-height: 90vh;
    background-color: $backgroundColorAccentOpaque;
    border: 2px solid #4ba5be;
  }
  .modal-body {
    max-height: 90%;
    overflow-y: scroll;
    background-color: $backgroundColorAccent;
  }

}

.modal-fullscreen {
  .talent-tree-container {
    height: 100%;
  }
  .modal-header {
    .modal-title {
      width: 100%;
      line-height: 1.2;
    }
    background-color: $backgroundColorDarkBlueOpaque;
    border-bottom-color: #4ba5be;
    color: #bbb;

    .header-row {
      display: flex;
      align-items: center;
      width: 100%;

      .filters {
        margin-left: auto;
        align-items: center;
        display: flex;
      }

      .tree-filter {
        margin-right: 10px;
        align-items: center;
        display: flex;
        span {
          margin-right: 6px;
        }
      }

      select {
        max-width: 200px;

      }

      input {
        max-width: 200px;
      }

      .search-icon {
        margin: 0;
        position: absolute;
        right: 20px;
        background: transparent;
      }
    }
  }
  .modal-footer {
    border-top-color: #4ba5be;
    background-color: $backgroundColorDarkBlueOpaque;
  }
  .modal-content {
    background-color: $backgroundColorAccentOpaque;
    border: 2px solid #4ba5be;

  }
  .modal-body {
    background-color: $backgroundColorAccent;
    padding-left: $spacing-large;
    display:flex;
    flex-direction: column;
  }

}




.feature-table-tr {
  td {
    cursor: pointer;
  }
}

.feature-table-tr-current-selected {
  //cursor: default;
  //td {
  //  cursor: default;
  //}
}

.feature-table-tr-selected {
  td {
    background-color: #816719;
  }
  .bg-secondary {
    padding: 4px;
    width: 17px;
  }
  .bg-success {
    padding: 4px;
    width: 17px;
  }
  .hidden {
    visibility: hidden;
  }
}

